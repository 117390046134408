<template>
	<div class="test">
		<div class="page_1">
			<PageNav :show_num="4" />
			<div class="top-nav-class" v-show="loadedRatio!==1">
			</div>
			<pdf :src="pdf_url_1" ref="pdf" v-show="loadedRatio===1" :page="currentPage" @num-pages="pageCount=$event"
				@progress="loadedRatio = $event" @page-loaded="currentPage=$event" @loaded="loadPdfHandler"
				@link-clicked="currentPage = $event" style="display: inline-block;width:100%" id="pdfID"></pdf>
			<!-- 加载未完成时，展示进度条组件并计算进度 -->
			<div class="progress" v-show="loadedRatio!==1">
				<el-progress type="circle" :width="70" color="#53a7ff"
					:percentage="Math.floor(loadedRatio * 100)"></el-progress>
				<br>
				<!-- 加载提示语 -->
				<span>{{remindShow}}</span>
			</div>
			<pdf :src="pdf_url_2"></pdf>
		</div>

		<PageBot :show_num="4" />
		<el-backtop class="back_up" target="" :bottom="100" :visibility-height="620" :right="10">
			<svg class="icon icon_xiangshang" aria-hidden="true">
				<use xlink:href="#icon-xiangshang"></use>
			</svg>
		</el-backtop>
	</div>
</template>

<script>
	import pdf from 'vue-pdf'
	import ProdSpec from "@/components/ProdSpec.vue";
	import PageNav from "@/components/PageNav.vue"
	import PageBot from "@/components/PageBottom.vue"
	import Swiper from "swiper";
	import "swiper/css/swiper.min.css";
	import {
		resetRem
	} from '@/all_js/all';
	import {
		clickEffect
	} from "@/all_js/click";
	export default {
		name: 'KS8223',
		components: {
			PageBot,
			PageNav,
			ProdSpec,
			pdf
		},
		destroyed() {
			// 在页面销毁时记得清空 setInterval
			clearInterval(this.intervalID)
		},
		mounted() {
			// 更改 loading 文字
			this.intervalID = setInterval(() => {
				this.remindShow === this.remindText.refresh ?
					this.remindShow = this.remindText.loading :
					this.remindShow = this.remindText.refresh
			}, 4000)
		},
		data() {
			return {
				pdf_url_1:this.api_base_url + "/offical-img/pdf/ZT6250/pg1.pdf",
				pdf_url_2:this.api_base_url + "/offical-img/pdf/ZT6250/pg2.pdf",
				avtive_nav: "",
				screenWidth: null,
				list_status: true,
				avtive_img: 0,
				// ----- loading -----
				remindText: {
					loading: 'Loading...',
					refresh: 'Loading...'
				},
				remindShow: 'Loading...',
				intervalID: '',
				// 当前页数
				currentPage: 0,
				// 总页数
				pageCount: 0,
				// 加载进度
				loadedRatio: 0
			}
		},
		methods: {
		
			// pdf加载时
			loadPdfHandler(e) {
				// 加载的时候先加载第一页
				this.currentPage = 1
			},
		
			// 禁用鼠标右击、F12 来禁止打印和打开调试工具
			prohibit() {
				// console.log(document)
				document.oncontextmenu = function() {
					return false
				}
				document.onkeydown = function(e) {
					if (e.ctrlKey && (e.keyCode === 65 || e.keyCode === 67 || e.keyCode === 73 || e.keyCode === 74 || e
							.keyCode === 80 || e.keyCode === 83 || e.keyCode === 85 || e.keyCode === 86 || e
							.keyCode === 117)) {
						return false
					}
					if (e.keyCode === 18 || e.keyCode === 123) {
						return false
					}
				}
			}
		}
	}
</script>
<style scoped lang="less">
	* {
		margin: 0;
		padding: 0;
		// transition: all 1s; // 设置动画效果
	}

	li {
		list-style-type: none;
	}

	.page_1 {
		width: 100%;
		min-height: 90vh;
		// object-fit: cover; // 保持图片缩放时候不变形
		// background-image: url("../../assets/images/prods/zt6250/pg1.png");
		// background-repeat: no-repeat;
		// background-size: cover;
		// background-position: center center;
		// -webkit-user-select: none;
		// -moz-user-select: none;
		// -ms-user-select: none;
	}
	.page_2{
		width: 100%;
		// height: 265vh;
		// object-fit: cover; // 保持图片缩放时候不变形
		// background-image: url("../../assets/images/prods/zt6250/pg2.png");
		// background-repeat: no-repeat;
		// background-size: cover;
		// background-position: center center;
		// -webkit-user-select: none;
		// -moz-user-select: none;
		// -ms-user-select: none;
	}




	.back_up {
		height: 40px;
		width: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 30px;
		background-color: #49b1f5;

		.icon_xiangshang {
			height: 30px;
			width: 30px;
			border-radius: 30px;
		}
	}

	.back_up:hover {
		background-color: #49b1f5;
	}
</style>
<style scoped lang="less">
	// @media only screen and (min-width:1371px) {}
</style>
<style scoped>
	.top-nav-class{
		width:100%;
		height:84px;
		background-color: #2981de;
	}
	/* ------------------- 进度条 ------------------- */
	.progress {
		margin-top: 40vh;
		text-align: center;
		font-size: 20px;
	}

	.progress>span {
		color: #199edb;
		font-size: 26px;
	}
</style>